<template>
  <main class="reach-your-community">
    <b-container class="analyticsContainer" fluid>
      <b-row>
        <b-col>
          <h1>Stand Out</h1>
          <p>
            Better website means better communication <br />
            with your community.
          </p>
        </b-col>
      </b-row>
      <img :src="require('../assets/images/manage/web1.png')" alt="" />
    </b-container>
    <b-container class="container2" fluid>
      <b-row>
        <b-col>
          <h1>Customized website</h1>
          <p>
            First impression is the most important <br />
            part of a student’s experience in your school, <br />
            whiteStrings team will talk to you to know <br />
            what you want to see in your website for <br />
            your community.
          </p>
        </b-col>
        <b-col cols="7">
          <img :src="require('../assets/images/manage/sm.png')" alt="" />
        </b-col>
      </b-row>
    </b-container>
    <b-container class="container3" fluid>
      <b-row>
        <b-col cols="7">
          <img :src="require('../assets/images/manage/sm3.png')" alt="" />
        </b-col>
        <b-col>
          <h1>A team to protect your <br />website</h1>
          <p>
            Our technical team is here with <br />
            you to keep your website secured and <br />
            updated to prevent unwanted hackers <br />
            to steal your valuable data.
          </p>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="partnershipForm" fluid>
      <b-row>
        <b-col class="colLeft">
          <h1>
            Be part of our Beta-School <br />
            partnership program
          </h1>
          <p>
            Our experts are with you each step<br />
            of the way to help your school <br />
            goals and increase your <br />
            success.
          </p>
        </b-col>
        <b-col class="colRight">
          <h4>
            Let us know what you need and we’ll be happy to <br />
            provide you the perfect platform for your school.
          </h4>

          <b-form>
            <b-row>
              <b-col cols="6">
                <b-form-group
                  id="input-group-1"
                  label="First Name*"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="name"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  id="input-group-1"
                  label="Last Name*"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="email"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group
                  id="input-group-1"
                  label="Business Name*"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="name"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  id="input-group-1"
                  label="Mobile Name*"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="email"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6">
                <b-form-group
                  id="input-group-1"
                  label="Country*"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="name"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group
                  id="input-group-1"
                  label="Which Type of School are you?*"
                  label-for="input-1"
                >
                  <b-form-input
                    id="input-1"
                    type="email"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <span class="consent">
              I consent to receive communications from whiteStrings containing
              news, updates and<br />
              promotions about whiteStrings and its products and services. I
              understand that I can withdraw<br />
              my consent at anytime.
            </span>

            <b-form-checkbox
              id="checkbox-1"
              v-model="status"
              name="checkbox-1"
              value="accepted"
              unchecked-value="not_accepted"
              class="checkboxWs"
            >
              I agree to receive email and mobile communications from
              whiteStrings.*
            </b-form-checkbox>

            <span class="consent">
              By clicking submit below, you consent to allow whiteStrings to
              store and process the personal <br />
              information submitted above to provide you the content requested.
            </span>
            <div class="btnContainer">
              <b-button class="btnRegister">Register</b-button>
            </div>
          </b-form>
        </b-col>
      </b-row>
    </b-container>
  </main>
</template>
<script>
export default {
  name: "ReachYourCommunity",
};
</script>
<style lang="scss">
@import "@/assets/styles/reachyourcommunity.scss";
</style>